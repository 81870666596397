import isPlainObject from 'is-plain-object';
import CryptoJS from 'crypto-js';
import Bowser from "bowser"; 

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: 'camera' | 'microphone') {
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: permissionName });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export function getDecryptedPWD(pwd: string) {
  const DECODE_KEY = "1!2kapTea@3";
  let decode = "";

  if (pwd) {
    decode = CryptoJS.AES.decrypt(pwd, `${DECODE_KEY}`).toString(CryptoJS.enc.Utf8)
  }
  return decode
}

export function maskIdentity(identity: string, meeting: any) {
  const datetime = new Date().getTime();
  let identifier = "###";

  if (meeting && Boolean(meeting.isChaperoning) === true) {
    identifier = "###CP##";
  } else {
    if (meeting && meeting && meeting.is_anonymous) {
      identifier = "###AP##";
    }
  }

  const maskedIdentity = `${identity}${identifier}${datetime}`;

  return maskedIdentity;
}

export function getIdentityInitials(identity: string) {
  const identifier = identity.split('###')[1] || '';
  let title = identity.split('###')[0];
  
  if (identifier.startsWith('AP##')) {
    title = "Anonymous Participant";
  } else if (identifier.startsWith('CP##')) {
    title = "Chaperoning Mode";
  }

  return title.split(' ').map((x: string) => { return x[0] }).join('').slice(0, 2).toUpperCase();
}

export function removeIdentityMasking(identity: string) {
  const identifier = identity.split('###')[1] || '';
  let title = identity.split('###')[0];

  if (identifier.startsWith('AP##')) {
    title = "Anonymous Participant";
  } else if (identifier.startsWith('CP##')) {
    title = "Chaperoning Mode";
  }

  return title;
}

export function getBrowserDetails() {
  const browserDetails = Bowser.parse(window.navigator.userAgent);
  
  return browserDetails;
}