import React from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// import Snackbar from '../../Snackbar/Snackbar';

import { maskIdentity } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    // width: '100%',
    // border: '2px solid #aaa',
    // margin: '1em 0',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '& .micIcon *': {
      fill: '#10416D'
    },
    '& .videoOnIcon *': {
      fill: '#10416D'
    }
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      // justifyContent: 'space-between',
      justifyContent: 'center',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
    '& .micIcon *': {
      fill: '#10416D'
    },
    '& .videoOnIcon *': {
      fill: '#10416D'
    }
  },
}));

function fetchConnectedParticipants(meetingId: string) {
  const api_key = window.sessionStorage.getItem('m_key');
  const m_key = api_key ? api_key : "";

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/room/${meetingId}/connected-participants`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'X-API-KEY': m_key
    },
  });
}
interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  topicName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, topicName }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, meeting } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = async () => {
    // const datetime = new Date().getTime();
    // const jtoken = window.sessionStorage.getItem('jtoken');
    // const identifier = jtoken ? jtoken : datetime;
    // console.log("identifier",identifier);
    // const participantResponse = await fetchConnectedParticipants(roomName);
    // const participantJsonResponse = await participantResponse.json();
    // console.log("participantJsonResponse", participantJsonResponse);

    // const filteredParticipants = participantJsonResponse.filter((participant: any) => {
    //   const participantIdentity = participant.identity.split("##")[0];
    //   console.log("participantIdentity", participantIdentity);
    //   if(participantIdentity === name) {
    //     return participant;
    //   }
    // })

    // let identifier = "###NP##";
    // if (meeting && Boolean(meeting.isChaperoning) === true) {
    //   identifier = "###CP##";
    // } else {
    //   if (meeting && meeting && meeting.is_anonymous) {
    //     identifier = "###AP##";
    //   }
    // }

    // const participantName = `${name}${identifier}${datetime}`;
    const participantName = maskIdentity(name, meeting);
    console.log("participantName", participantName);
    getToken(participantName, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>

        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join {topicName}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={maskIdentity(name, meeting)} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              {/* <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} /> */}
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </Hidden>
            {/* <SettingsMenu mobileButtonClass={classes.mobileButton} /> */}
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={(disableButtons || (meeting && Boolean(meeting.isChaperoning)))} />
                {/* <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} /> */}
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
