import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';

import DialecticaLogo from '../../icons/DialecticaLogo';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      top: 0,
      left: 0,
      right: 0,
      height: `${theme.headerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    dialecticaLogo: {
      top: '10px',
      left: '12px',
      position: 'absolute',
      height: '40px',
      // enableBackground: 'new 0 0 254 61.9'
    },
  })
);

export default function Header() {
  const classes = useStyles();
  // const { room } = useVideoContext();
  const { meeting } = useAppState();

  return (
    <>
      <header className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item>
            <Grid container justifyContent="center">
              <DialecticaLogo className={classes.dialecticaLogo} />
              <Typography variant="body1">{meeting!.topic}</Typography>
            </Grid>
          </Grid>
          {/* <Hidden smDown> */}
          {/* <Grid style={{ flex: 1 }}>
          </Grid> */}
          {/* </Hidden> */}
        </Grid>
      </header>
    </>
  );
}
