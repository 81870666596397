import React, { PropsWithChildren, ChangeEvent, useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Snackbar from '../Snackbar/Snackbar';

import { useAppState } from '../../state';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const phoneNumberRegex = new RegExp(/^\+[0-9]+$/);

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
}))

interface DialerDialogProps {
  open: boolean;
  onClose(): void;
}

function DialerDialog({ open, onClose }: PropsWithChildren<DialerDialogProps>) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { meeting } = useAppState();
  const { meeting_id } = meeting

  useEffect(() => {
    setError('');
    setPhoneNumber('');
  }, [open]);

  const sendInvite = useCallback((phoneNumber: string) => {

    const api_key = window.sessionStorage.getItem('m_key');
    const m_key = api_key ? api_key : "";

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/invite/number`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-API-KEY': m_key
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        meeting_id: meeting_id
      }),
    }).then(async res => {
      const jsonResponse = await res.json();
      console.log("jsonResponse", jsonResponse);

      if (!res.ok) {
        const error = new Error(jsonResponse.error?.message || 'There was an error while sending invitation.');
        error.code = jsonResponse.error?.code;

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  const invite = () => {
    setError('');
    setInProgress(true);
    sendInvite(phoneNumber)
      .then(() => {
        setIsSuccess(true);
        setPhoneNumber('');
        setInProgress(false);
        onClose();
      })
      .catch(err => {
        console.log("err", err)
        setInProgress(false);
        setError('Unable to process invite request, Please retry again after sometime or contact Administator');
      });
  };

  const handleDialButton = () => {
    if (phoneNumber.trim() === "" || !phoneNumberRegex.test(phoneNumber)) {
      setError("Please enter a valid phone number");
    } else {
      invite();
    }
  }

  return (
    <>
      <Snackbar
        variant="info"
        headline="Success"
        message="Call invitation sent..."
        open={isSuccess}
        handleClose={() => setIsSuccess(false)}
      />
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Invite Participant Using Phone Number</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.textContainer}>
                <InputLabel shrink htmlFor="input-meetingId">
                  Phone Number
                </InputLabel>
                <TextField
                  id="input-phonenumber"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  placeholder="Enter phone number with country code, e.g. +48111222333"
                />
              </div>
            </Grid>
            <div>
              {error && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {error}
                </Typography>
              )}
            </div>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={!phoneNumber.length || inProgress}
            className={classes.submitButton}
            onClick={handleDialButton}
          >
            Invite
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            className={classes.submitButton}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialerDialog;
